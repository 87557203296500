/* src/LoginPage.css */
.login-container {
    max-width: 400px;
    margin: auto;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
}

h2 {
    text-align: center;
    margin-bottom: 1em;
    color: black;
}

.form-group {
    margin-bottom: 1em;
}

label {
    margin-bottom: .5em;
    color: #333333;
    display: block;
}

input {
    border: 1px solid #CCCCCC;
    padding: 0.5em; /* Space inside the input */
    font-size: 1em; /* Font size */
    width: calc(100% - 1em); /* Full width of the container, considering padding */
    border-radius: 10px; /* Rounded corners */
    box-sizing: border-box; /* Includes padding and border in the input's total width */
}

button {
    background-color: rgba(195, 27, 27, 0.808);
    color: white;
    border: none;
    padding: 0.7em;
    font-size: 1em;
    width: 100%; /* Full width of the container */
    border-radius: 20px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.container {
    position: relative; /* Establishes a positioning context for the child */
    height: 100vh; /* Full height of the viewport for demonstration */
    border: 1px solid #ccc;
    background: #f9f9f9; /* Fallback color */
    background-image: url('/assets/images/axombanner.png');
    background-size: cover; /* Cover the container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* No repeat for the background image */
}

.bottom-right {
    position: absolute; /* Allows for precise positioning within the container */
    bottom: 60%; /* Adjust based on preference */
    right: -3%; /* Adjust based on preference */
    background: rgba(252, 178, 59, 0); /* Transparent background */
    color: rgb(255, 255, 255); /* Ensures readability */
    padding: 10px; /* Padding inside the container */
    border-radius: 8px; /* Optional: rounded corners */
    width: 90%; /* Adjust width to fit the screen */
    max-width: 400px; /* Max width for larger screens */
    height: auto; /* Adjust height to fit content */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    display: flex; /* Flexbox for better layout control */
    flex-direction: column; /* Stack children vertically */
    align-items: center; 
}

.bottom-right-image {
    width: 100px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Space between image and label */
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
    .login-container {
        width: 90%; /* Adjust width for smaller screens */
        padding: 0.5em; /* Reduce padding */
        
    }
    .container {
        background-image: url('/assets/images/axomsmall.png');
    }

    input, button {
        width: 100%; /* Full width on smaller screens */
        font-size: 0.9em; /* Slightly smaller font size */
    }

    .bottom-right {
        width: 85%; /* Adjust width for smaller screens */
        bottom: 25%; /* Move up a bit */
        right: 7%; 
    }

    .bottom-right-image {
        width: 80px; /* Adjust image size for smaller screens */
    }
}